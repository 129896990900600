.label {
  margin-bottom: 1rem;
}
.container {
  display: flex;
  flex-direction: column;
  font-size: 16rem;
  line-height: 150%;
  position: relative;
  .label {
    font-size: 14rem;
  }
  .inputContainer {
    display: flex;
    align-items: center;
  }
  .subLabel {
    font-size: 12rem;
    color: #747474;
    white-space: pre-line;
  }
  input {
    padding: 12rem 16rem;
    border-radius: 16rem;
    font-size: 16rem;
    line-height: 170%;
    outline: none;
    border: 1rem solid var(--PrimitiveColors-Gray-03, #bfbfbf);
    background: var(--Light-Background-Primary, #fff);
  }
  .rightIcon {
    position: absolute;
    right: 20rem;
    top: 40rem;
  }
  .unit {
    font-size: 14px;
    font-weight: 500;
    margin-left: 8rem;
    display: inline;
  }
  .error {
    font-size: 14rem;
    color: #e91717;
    margin-left: 8rem;
    margin-top: 4rem;
    min-height: 24rem;
  }
}
