.container {
  height: 60%;
  padding: 16px;
  .body {
  padding: 32px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100% - 32px);
  .registerContainer {
    padding: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height:calc(20% );
    .registerTitle {
      margin-bottom: 20px;
      color: #000;
      font-size: 24rem;
      font-style: normal;
      font-weight: 700;
      line-height: 150%;
    }
    .registerHeader {
      color: #000;
      width: 100%;
      text-align: start;
      font-size: 14rem;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
    }
    .registerForm {
      width: 100%;
      text-align: start;
      margin: 40px 0;
    }
    .registerCaption {
      margin-top: 8px;
      color: #000;
      font-size: 14rem;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
      .link {
        cursor: pointer;
        color: #1784e9;
        font-size: 14rem;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 21px */
        letter-spacing: 0.56px;
        text-decoration-line: underline;
        text-decoration-style: solid;
        text-decoration-skip-ink: none;
        text-decoration-thickness: auto;
        text-underline-offset: auto;
        text-underline-position: from-font;
      }
    }
  }  
  }
}

.breadCrumb {
  margin-top: auto;
}
