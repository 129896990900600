.container {
  min-height: calc(100% - 250rem);
  .body {
    display: flex;
    flex-direction: column;
    padding: 0 16rem;
    height: calc(100% - 64rem);
    .avatar {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 24rem 0;
    }
    .educationPlan {
      margin-top: 48rem;
      .row {
        display: flex;
        align-items: center;
        font-size: 14rem;
        .label {
          font-weight: 700;
          border-right: solid 2rem #d8d8d8;
          padding: 24rem;
          padding-left: 64rem;
          width: 100rem;
        }
        .value {
          padding: 24rem;
        }
        .current {
          padding-bottom: 46rem;
        }
      }
    }
    .teacherComment {
      margin-top: 48rem;
      .link {
        cursor: pointer;
        color: #1784e9;
        font-size: 14rem;
        text-decoration-line: underline;
      }
    }
    .breadCrumb {
      margin-top: auto;
    }
  }
}
