.container {
  width: 100%;
  font-size: 16rem;
  line-height: 150%;
  font-weight: 500;
  letter-spacing: 0.64rem;
  display: flex;
  flex-direction: column;

  .body {
    padding: 0 16rem;
    .block {
      .label {
        text-align: left;
        font-size: 14rem;
        font-style: normal;
        font-weight: 700;
        line-height: 150%; /* 21px */
        letter-spacing: 0.56rem;
        margin-bottom: 5rem;
      }
      .listWithoutPad {
        margin-bottom: 16rem;
      }
      .disableList {
        margin-bottom: 16rem;
        border-radius: 16rem;
        padding: 16rem 0;
        text-align: center;
        background-color: #d8d8d8;
        color: #747474;
        font-size: 14rem;
        font-weight: 500;
        line-height: 150%; /* 21px */
        letter-spacing: 0.56rem;
      }
      .list {
        margin-bottom: 16rem;
        border-radius: 16rem;
        background-color: white;
        padding: 12rem 0;
        .line:not(:first-child) {
          border-top: solid 1px #d8d8d8;
        }
        .line {
          padding: 12rem 16rem;
          display: flex;
          align-items: center;
          .icon {
            height: 36rem;
            width: 36rem;
            margin-right: 8rem;
          }
          .nameBlock {
            text-align: left;
            .name {
              font-size: 16rem;
              font-weight: 500;
              line-height: 150%; /* 24px */
              letter-spacing: 0.64rem;
            }
            .option {
              color: #66c6bb;
              font-size: 12rem;
              font-weight: 500;
              line-height: 150%; /* 18px */
              letter-spacing: 0.48rem;
            }
            .addChild {
              color: #747474;
              font-size: 14rem;
              font-style: normal;
              font-weight: 500;
              line-height: 150%; /* 21px */
              letter-spacing: 0.56rem;
            }
          }
          .arrowIcon {
            margin-left: auto;
            width: 20rem;
            height: 20rem;
          }
        }
      }
    }
  }
  .breadCrumb {
    margin-top: auto;
  }
}
