.container {
  position: absolute;
  height: 100%;
  width: calc(100% - 32rem);
  padding: 0 16rem;
  background-color: rgb(50, 50, 50);
  display: flex;
  justify-content: center;
  align-items: center;
  .card {
    width: 100%;
    padding-top: 30rem;
    background-color: #fff;
    border-radius: 16rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    .ribbonImage {
      position: absolute;
      top: -28rem;
      left: calc(50% - 143px);
    }
    .squareImage {
      position: absolute;
      top: -28rem;
    }
    .title {
      color: #000;
      font-size: 24rem;
      font-weight: 700;
      line-height: 140%; /* 28px */
      letter-spacing: 0.8rem;
      margin: auto;
    }
    .image {
      height: 128rem;
      margin: auto;
    }
    .nonBadgeArea {
      display: flex;
      align-items: center;
      .nonBadgeText {
        font-size: 16rem;
        font-weight: 700;
        margin-left: 4rem;
      }
    }
    .text {
      color: #747474;
      font-size: 14rem;
      font-weight: 500;
      line-height: 150%; /* 21px */
      letter-spacing: 0.56rem;
      padding: 0 16rem;
      margin: auto;
      text-align: center;
    }
    .optionalContainer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: #fffbd6;
      padding: 8rem;
      margin: 0 16rem;
      width: calc(100% - 48rem);
      .line {
        display: flex;
        justify-content: center;
        align-items: center;
        .checkIcon {
          height: 16rem;
          margin-right: 8rem;
        }
        .optionalText {
          color: #747474;
          font-size: 12rem;
          font-weight: 500;
          line-height: 200%; /* 24px */
          letter-spacing: 0.48px;
        }
      }
    }
    .buttonWrapper {
      display: flex;
      justify-content: center;
      width: 100%;
      margin-top: 12rem;
    }
    .support {
      position: relative;
      display: flex;
      justify-content: center;
      width: 100%;
      margin-top: 60rem;
      padding-bottom: 16rem;
      .supportMessage {
        font-size: 10rem;
        font-style: normal;
        font-weight: 700;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: 266rem;
        top: -34rem;
      }
      .supportIcon {
        position: absolute;
        left: 14rem;
        z-index: 99;
        bottom: 13rem;
      }
    }
  }
}
