.container {
  font-size: 14rem;
  font-weight: 700;
  line-height: 150%; /* 21px */
  letter-spacing: 0.56rem;
  border-radius: 16rem;
  background-color: #fff;
  .header {
    display: flex;
    min-height: 32rem;
    .heartIcon {
      width: 20rem;
      height: 20rem;
      padding: 0 8rem;
    }
    .label {
      min-width: 200rem;
      border-radius: 16rem 0 16rem 0;
      background-color: #008e7e;
      display: flex;
      align-items: center;
      color: #fff;
      position: relative;
      padding-left: 10rem;
      font-size: 12rem;
      .triangle {
        position: absolute;
        top: 0;
        border-radius: 0 0 0 16rem;
        right: -23rem;
        border: 16rem solid transparent;
        border-top: 16rem solid #008e7e;
        border-left: 16rem solid #008e7e;
      }
    }
    .date {
      margin-left: auto;
      margin-top: 8rem;
      margin-right: 8rem;
      color: #747474;
      font-size: 12rem;
      font-style: normal;
      font-weight: 500;
      line-height: 140%; /* 19.6px */
    }
    .tagContainer {
      margin-left: auto;
      margin-top: 8rem;
      margin-right: 16rem;
      border: 1px solid var(--PrimitiveColors-Orange-01, #f27400);
      font-size: 14rem;
      font-style: normal;
      font-weight: 700;
      line-height: 140%; /* 19.6px */
      color: #f27400;
      display: flex;
      padding: 0 6rem;
      align-items: center;
      .tag {
        margin-left: 2rem;
      }
    }
  }
  .body {
    display: flex;
    align-items: center;
    padding: 20rem 12rem;
    .badge {
      height: 40rem;
      width: 40rem;
      margin-right: 12rem;
    }
    .title {
      font-size: 16rem;
      margin-right: 12rem;
    }
    .arrow {
      margin-left: auto;
    }
  }
}

.modalLayout {
  font-size: 14rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  .butonArea {
    width: 128rem;
    height: 128rem;
    margin: 32rem auto;
  }
  .message {
    font-weight: 500;
    text-align: center;
    margin-top: 24rem;
    color: var(--Text-Description, #747474);
  }
  .close {
    border-top: 1rem solid #d8d8d8;
    padding: 12rem 0 15rem;
  }
}
