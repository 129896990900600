.container {
  position: absolute;
  height: 100%;
  width: calc(100% - 32rem);
  padding: 0 16rem;
  background-color: rgb(50, 50, 50);
  display: flex;
  justify-content: center;
  align-items: center;
  .card {
    width: 100%;
    padding-top: 30rem;
    background-color: #fff;
    border-radius: 16rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    .ribbonImage {
      position: absolute;
      top: -28rem;
      left: calc(50% - 143px);
    }
    .title {
      color: #000;
      font-size: 20rem;
      font-weight: 700;
      line-height: 140%; /* 28px */
      letter-spacing: 0.8rem;
      margin: auto;
    }
    .image {
      height: 128rem;
      margin: auto;
    }
    .text {
      color: #747474;
      font-size: 14rem;
      font-weight: 500;
      line-height: 150%; /* 21px */
      letter-spacing: 0.56rem;
      padding: 0 16rem;
      margin: auto;
      text-align: center;
    }
    .optionalContainer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: #fffbd6;
      padding: 8rem;
      margin: 0 16rem;
      width: calc(100% - 48rem);
      .line {
        display: flex;
        justify-content: center;
        align-items: center;
        .checkIcon {
          height: 16rem;
          margin-right: 8rem;
        }
        .optionalText {
          color: #747474;
          font-size: 12rem;
          font-weight: 500;
          line-height: 200%; /* 24px */
          letter-spacing: 0.48px;
        }
      }
    }
    .buttonArea {
      width: 100%;
      margin-top: 24rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      .firstSettingImage {
        margin-bottom: 8rem;
      }
    }
    .firstSettingContainer {
      display: flex;
      padding: 12rem 16rem;
      justify-content: center;
      align-items: center;
      border-radius: 24rem;
      background: #fabf00;
      color: #000;
      text-align: center;
      /* 16-Bold */
      font-size: 16rem;
      font-weight: 700;
      line-height: 150%; /* 24px */
      letter-spacing: 0.64px;
      width: calc(100% - 64rem);
    }
    .lifeplanContainer {
      display: flex;
      padding: 12rem 16rem;
      justify-content: center;
      align-items: center;
      border-radius: 24rem;
      background: #fabf00;
      color: #000;
      text-align: center;
      /* 16-Bold */
      font-size: 16rem;
      font-weight: 700;
      line-height: 150%; /* 24px */
      letter-spacing: 0.64px;
      width: calc(100% - 64rem);
      margin: 24rem 16rem 32rem 16rem;
    }
    .actionContainer {
      display: flex;
      padding: 12rem 16rem;
      justify-content: center;
      align-items: center;
      border-radius: 24rem;
      background: #fabf00;
      color: #000;
      text-align: center;
      /* 16-Bold */
      font-size: 16rem;
      font-weight: 700;
      line-height: 150%; /* 24px */
      letter-spacing: 0.64px;
      width: calc(100% - 64rem);
      margin: 24rem 16rem 32rem 16rem;
    }
    .close {
      width: 100%;
      margin-top: auto;
      padding: 12rem 0;
      border-top: solid 1px #d8d8d8;
      text-align: center;
      color: #000;
      font-size: 14rem;
      font-weight: 500;
      line-height: 150%; /* 21px */
      letter-spacing: 0.56rem;
    }
  }
}
