.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #000;
  width: 100%;
  margin-bottom: 64px;

  .header {
    font-size: 16rem;
    font-weight: 700;
    line-height: 140%;
    margin: 8px 0;
  }
  .question {
    font-size: 20rem;
    font-weight: 700;
    line-height: 150%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .logo {
    width: 100%;
    margin: 8px 0;
  }

  .button {
    cursor: pointer;
    width: 100%;
    height: 48px;
    border-radius: 24px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 8px;
    text-align: center;
    .text {
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
    }
  }
  .active {
    background-color: #008e7e;
    color: #fff;
  }
  .link {
    cursor: pointer;
    color: #1784e9;
    font-size: 14rem;
    font-weight: 500;
    line-height: 150%; /* 21px */
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-decoration-skip-ink: none;
    text-decoration-thickness: auto;
    text-underline-offset: auto;
    text-underline-position: from-font;
  }
}
