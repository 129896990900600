.container {
  width: 80%;
  margin-left: auto;
  background: #ece6dc;
  border-radius: 32rem 0 0 32rem;
  display: flex;
  flex-direction: column;
  height: 100vh;
  .headerArea {
    display: flex;
    justify-content: end;
    .cancelImage {
      padding: 20rem;
      width: 16rem;
    }
  }

  .userInfo {
    width: calc(100% - 20rem);
    margin: 0 auto;
  }

  .buttonArea {
    display: flex;
    justify-content: center;
    margin: 12rem 0;
  }
  .menuListContainer {
    margin-top: auto;
    font-size: 16rem;
    text-align: left;
    font-weight: 700;
    color: white;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    height: 680rem;
  }
  .menuList {
    background: #008e7e;
    padding: 28rem;
    flex-grow: 1;
    border-radius: 0 0 0 32rem;
    overflow: auto;
    .lock {
      display: flex;
      align-items: center;
      color: #ccc;
    }
    .name {
      margin-bottom: 32rem;
      display: flex;
      align-items: center;
    }
    .name:last-child {
      margin-bottom: none;
    }
    .icon {
      margin-left: 10rem;
      width: 16rem;
    }
    .line {
      border-bottom: solid 1px #ccc;
      margin: 0 16rem;
    }
  }
}

.lineImageContainer {
  display: flex;
  .lineImage {
    width: 100%;
  }
}
