.container {
  .progressBarContainer {
    padding: 0 16rem;
    margin-top: 24rem;
    .progressContainer {
      position: relative;
      height: 8rem;
      background: rgba(255, 255, 255, 1);
      border-radius: 10rem;
      .progressBar {
        height: 100%;
        background-color: #008e7e;
        border-radius: 10rem;
        transition: width 0.3s;
      }

      .startNumber {
        position: absolute;
        top: 50%; // 縦方向の位置を中央に変更
        left: -8rem; // 横方向の位置を中央に変更
        transform: translateY(-50%);
        background-color: #008e7e;
        border-radius: 50%;
        width: 22rem;
        height: 22rem;
        text-align: center;
        line-height: 22rem;
        font-size: 14rem;
        font-weight: bold;
        color: white;
        border: 2rem solid #008e7e; // 青い境界線を追加
        z-index: 2; //重なった時はStartが優先
      }

      .endNumber {
        position: absolute;
        top: 50%;
        right: -6rem;
        transform: translateY(-50%);
        background-color: white;
        border-radius: 50%;
        width: 22rem;
        height: 22rem;
        text-align: center;
        line-height: 22rem;
        font-size: 14rem;
        font-weight: bold;
        border: 2rem solid #ffffff; // 青い境界線を追加
        z-index: 1; //重なった時はStartが優先
      }
    }
  }
  .question {
    margin-top: 20rem;
    text-align: center;
    color: #000000;
    font-style: normal;
    font-weight: 700;
    padding: 0 16rem;
    .questionNo {
      font-size: 16rem;
    }
    .questionContent {
      font-size: 20rem;
      margin-top: 8rem;
    }
  }
  .button {
    display: flex;
    justify-content: center;
    margin: 48rem 0 32rem;
  }
}
