.container {
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 24rem;
  text-align: center;
}
.mainContent {
  .title {
    margin: 16rem 0;
    font-weight: 800;
  }
  .inputArea {
    text-align: left;
    width: 90%;
    margin: 0 auto 4rem;
    font-weight: 800;

    .input {
      margin-bottom: 28rem;
      .inputSubTitle {
        color: grey;
      }
    }
  }
  .buttonArea {
    margin-top: 48rem;
  }
}
