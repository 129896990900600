.label {
  font-size: 14rem;
  margin-bottom: 6rem;
}

.subLabel {
  font-size: 12rem;
  color: #747474;
  margin-bottom: 6rem;
}

.checkItemContainer {
  background: white;
  border-radius: 16rem;
  overflow: hidden;
}

.checkChildItemContainer {
  background: white;
  border-radius: 16rem;
}

.checkItem {
  padding: 6.5rem 20rem 7.5rem;
  font-size: 16rem;
  border-bottom: 1px solid #d8d8d8;
}

.childrenCheckItem {
  padding: 6.5rem 20rem 7.5rem 28rem;
  font-size: 14rem;
  border-bottom: 1px solid #d8d8d8;
}

.checkItem:last-child {
  border: none;
}

.checkboxLabel {
  margin-left: 12rem;
  line-height: 150%;
  display: inline-block;
  width: calc(100% - 40rem);
}

.checkbox {
  accent-color: #008e7e;
}
