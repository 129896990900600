.footerWrapper {
  padding: 20rem;
  background: #008e7e;
}

.footerTitle {
  padding: 20rem 0;
  display: flex;
  justify-content: center;
}

.divider {
  border: 1rem solid #34b1a3;
}

.navMenu {
  color: #ffffff;
  padding: 5rem 10rem;
  font-size: 12rem;
  font-weight: 500;
}

.navMenuWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 35rem;
}

.arrowRightImage {
  width: 16rem;
  height: 16rem;
  padding: 5rem;
}

.exportImage {
  width: 16rem;
  height: 16rem;
  padding: 5rem;
}

.copyright {
  text-align: center;
  margin-top: 10rem;
  color: white;
  font-size: 10rem;
  font-weight: 500;
}
