.loadingContainer {
  width: 100%;
}

.logoImageContainer {
  margin-top: 30px;
  text-align: center;
}

.message {
  text-align: center;
  font-weight: bold;
  font-size: 18px;
}

.questionHeaderContainer {
  width: 100%;
  text-align: center;
  .image {
    margin: 0 auto;
    padding: 17px 0;
  }
}
