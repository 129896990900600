.container {
  border-radius: 16px;
  background-color: #f5f3f0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 8px;
  .title {
    color: #000;
    text-align: center;
    font-size: 20rem;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    margin-bottom: 8px;
  }
  .caption {
    border-radius: 24px;
    background: #008e7e;
    display: inline-flex;
    padding: 0px 10px 2px 10px;
    justify-content: center;
    align-items: center;
    .text {
      color: #fff;
      text-align: center;
      /* 14 - Bold */
      font-size: 14rem;
      font-style: normal;
      font-weight: 700;
      line-height: 150%; /* 21px */
      letter-spacing: 0.56px;
    }
  }
  .body {
    width: calc(100% - 16px);
    padding: 8px;
    .block {
      width: calc(100% - 10px);
      display: flex;
      border-left: 3px solid #008e7e;
      padding-left: 10px;
      position: relative;
      .year,
      .occupationYear {
        margin-top: 25px;
        color: #008e7e;
        font-size: 12rem;
        font-style: normal;
        font-weight: 700;
        line-height: 140%;
        margin-right: 8px;
      }
      .occupationYear {
        margin-top: 35px;
      }
      .point,
      .occupationPoint {
        position: absolute;
        width: 12px;
        height: 12px;
        border: 2px solid #008e7e;
        border-radius: 50%;
        background-color: #fff;
        top: 25px;
        left: -9px;
      }
      .occupationPoint {
        top: 35px;
      }
      .eventBlock {
        width: calc(100% - 45px);
        .contents,
        .occupationContents {
          margin-bottom: 16px;
          background-color: #fff;
          height: 50px;
          padding: 8px;
          display: flex;
          align-items: center;
          .childName {
            margin-right: 8px;
            min-width: 30px;
            min-height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            color: #fff;
            font-size: 8rem;
            font-style: normal;
            font-weight: 700;
            line-height: 150%; /* 12px */
            letter-spacing: 0.32px;
          }
          .event {
            color: #000;
            font-size: 14rem;
            font-style: normal;
            font-weight: 700;
            line-height: 140%;
            margin-right: 8px;
          }
        }
        .occupationContents {
          height: 70px;
          background-color: #e2f9ef;
          .occupationEvent {
            color: #000;
            font-size: 12rem;
            font-style: normal;
            font-weight: 700;
            line-height: 140%;
            margin-right: 8px;
            .averageSalary {
              color: #747474;
            }
          }
          .occupationIcon {
            height: 50px;
            margin-left: auto;
          }
        }
      }
      // .eventBlock:not(:first-child) {
      //   margin-top: 16px;
      // }
      // .eventBlock:last-child {
      //   margin-bottom: 16px;
      // }
    }
  }
}
