@use "../../static/scss/common.scss" as common;
.loadingContainer {
  min-width: 100%;
  min-height: 100%;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  display: flex;
  justify-content: center;
  z-index: 1;
  .inner {
    background-color: #ece6dc;
    max-width: common.$max-width;
    width: 100%;
    height: 100%;
  }
}
