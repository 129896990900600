.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .banner {
    margin-bottom: 24px;
    width: 100%;
    background: #008e7e;
    color: #fff;
    text-align: center;
    font-size: 12rem;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
  }
  .header {
    margin-bottom: 24px;
  }

  .top {
    margin-bottom: 24px;
  }
  .buttonArea {
    width: calc(100% - 32px);
    position: relative;
    margin: 24px 0;
    cursor: pointer;
    .teacher {
      position: absolute;
      top: -30px;
    }
    .button {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 48px;
      border-radius: 24px;
      background: #fabf00;
      color: #000;
      font-size: 16rem;
      font-style: normal;
      font-weight: 700;
      line-height: 150%;
    }
  }
  .link {
    margin-bottom: 12px;
    color: #1784e9;
    text-align: center;
    font-size: 14rem;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 21px */
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-decoration-skip-ink: none;
    text-decoration-thickness: auto;
    text-underline-offset: auto;
    text-underline-position: from-font;
    cursor: pointer;
  }
}
