.container {
  .teacherComment {
    width: calc(100% - 24rem);
    margin: auto;
  }
  .headerArea {
    background-color: #f9f7f5;
    padding-bottom: 12rem;
  }
  .userInfo {
    padding: 0 12rem;
    margin-top: 12rem;
  }
  .frameContainer {
    padding: 10rem;
    margin-top: 12rem;
    flex: 1;
    max-width: calc(50% - 20rem);
    .action {
      display: flex;
      align-items: end;
      justify-content: center;
      margin: auto;
      .rock {
        display: flex;
        flex-direction: column;
        align-items: center;
        .icon {
          height: 40rem;
          width: 40rem;
        }
        .caption {
          color: #747474;
          font-size: 12rem;
          font-weight: 700;
          line-height: 140%;
        }
      }
    }
    .actionNum {
      font-size: 26rem;
      color: #008e7e;
      font-weight: 700;
      max-width: 70%;
      word-break: break-word;
    }
    .actionNumSub {
      font-size: 14rem;
      color: #008e7e;
      font-weight: 700;
    }
    .actionText {
      color: #008e7e;
      font-weight: 500;
      font-size: 12rem;
      margin-left: 6rem;
    }
    .badge {
      display: flex;
      justify-content: space-between;
      max-width: 90%;
      margin: auto;
      font-size: 18rem;
      color: #008e7e;
      font-weight: 700;
      text-align: center;
      .badgeCircle {
        position: relative;
        width: 36rem;
        height: 36rem;
      }
      .badgeImg {
        position: absolute;
        top: 0;
        left: 0;
      }
      .inCircle {
        background-color: rgb(255, 247, 212);
        width: 28rem;
        height: 28rem;
        position: absolute;
        border-radius: 50%;
        top: 4rem;
        right: 4rem;
      }
    }
    .familyContainer {
      display: flex;
      justify-content: center;
      gap: 16rem;
      max-width: 75%;
      margin: auto;
      .family {
        padding: 0 0.4rem;
      }
    }
  }
  .wideFrameContainer {
    padding: 10rem;
    margin-top: 12rem;
    flex: 1;
    .diagnosis {
      font-weight: 700;
      color: #008e7e;
      text-align: center;
      .diagnosisTitle {
        font-size: 14rem;
      }
      .diagnosisContent {
        font-size: 20rem;
      }
    }
    .adequecyContainer {
      text-align: center;
      .adequencyMessage {
        background: #ed7e99;
        padding: 6rem 0 9rem;
        margin-top: 1rem;
        color: white;
        font-size: 12rem;
        font-weight: 700;
        border-radius: 0 0 4rem 4rem;
      }
      .imageArea {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        position: relative;
        .adequency {
          width: 120rem;
        }
        .checkArea {
          font-size: 14rem;
          font-weight: 700;
          .check {
            display: flex;
            align-items: center;
            .text {
              margin-left: 4rem;
            }
          }
        }
      }
      .graph {
        height: 140rem;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
      }
      .rock {
        display: flex;
        justify-content: center;
        align-items: center;
        .icon {
          height: 40rem;
          width: 40rem;
          margin: 8rem 0;
        }
        .caption {
          color: #747474;
          font-size: 12rem;
          font-weight: 700;
          line-height: 140%;
        }
      }
    }
  }
  .contentContainer {
    margin-bottom: 2rem;
  }
  .frameContentWrapper {
    display: flex;
    align-items: start;
  }
  .bannerCounselling {
    margin-top: 40rem;
    margin-bottom: 80rem;
    padding: 0 10rem;
    position: relative;
  }
  .breadCrumb {
    margin-top: auto;
  }
  .bubble {
    position: absolute;
    top: -14rem;
    right: 20rem;
    left: 20rem;
    font-size: 16rem;
    text-align: center;
    font-weight: 700;
    padding: 3rem 16rem;
    background-color: #008e7e;
    color: white;
    border-radius: 5rem;
  }

  .bubble:before {
    content: "";
    position: absolute;
    bottom: -25rem;
    left: 48rem;
    margin-top: -16rem;
    border: 15rem solid transparent;
    border-right: 15rem solid #008e7e;
    transform: rotate(-90deg);
  }
}
