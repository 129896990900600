.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .tabList {
    display: flex;
    align-items: center;
    .tab {
      width: calc(100% / 2);
      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom: solid 1px #747474;
      font-size: 16rem;
      font-style: normal;
      font-weight: 500;
      line-height: 150%; /* 24px */
      letter-spacing: 0.64rem;
      padding-bottom: 8rem;
    }
    .active {
      border-bottom: solid 3px #008e7e;
      font-weight: 700;
    }
  }
  .teacherCommentWrapper {
    width: calc(100% - 32rem);
    margin: 0 auto;
  }
  .cardArea {
    padding-bottom: 64rem;
    .card {
      margin-top: 20rem;
    }
  }
  .breadCrumb {
    margin-top: auto;
  }
}
