.container {
  .title {
    font-size: 24rem;
    font-weight: 700;
    text-align: center;
    margin-top: 24rem;
  }
  .mainArea {
    padding: 16rem;
    .saveButton {
      margin-top: 16rem;
    }
    .linkContainer {
      text-align: center;
      margin-top: 20rem;
      p {
        /* テキストリンク */
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 150%;
        text-decoration-line: underline;
        color: #1784e9;
        cursor: pointer;
      }
    }
    .deleteButton {
      margin-top: 70rem;
    }
  }
  .modalLayout {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
  }
  .modalHeader {
    margin-top: 24rem;
    text-align: center;
    .modalTitle {
      font-size: 20rem;
      font-weight: 700;
    }
  }
  .modalBody {
    font-size: 14rem;
    font-weight: 500;
    color: #747474;
    margin-top: 12rem;
  }
  .modalFooter {
    display: flex;
    align-items: center;
    font-size: 16rem;
    border-top: 1rem solid #d8d8d8;
    .modalFooterButton1 {
      padding: 12rem 44rem;
      width: 50%;
      border-right: 1rem solid #d8d8d8;
      color: #1784e9;
    }
    .modalFooterButton2 {
      padding: 12rem 44rem;
      width: 50%;
      color: #e91717;
    }
  }
  .breadCrumb {
    margin-top: auto;
  }
}
