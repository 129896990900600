.label {
  font-size: 14rem;
  margin-bottom: 12rem;
}
.inputContainer {
  background: white;
  border-radius: 16rem;
  overflow: hidden;
  .inputArea {
    border-bottom: 1rem solid #d8d8d8;
    padding: 12rem 16rem;
    font-size: 16rem;
    .input {
      accent-color: #008e7e;
    }
    .inputLabel {
      display: inline-block;
      width: calc(100% - 25rem);
    }
  }
  .inputArea:last-child {
    border: none;
  }
}
