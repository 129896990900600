.container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(61 59 59);
  z-index: 1000;
  .modal {
    background: white;
    // TODO：これは渡せるようにする
    margin: auto;
    border-radius: 16rem;
    position: absolute;
    z-index: 999999;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
  }
}
