.container {
  .body {
    padding: 0 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(100% - 32px);
    .teacherImage {
      height: 162px;
      margin: 32px 0;
    }
    .title {
      color: #000;
      font-size: 24rem;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;
      margin-bottom: 16px;
    }
    .caption {
      color: #000;
      font-size: 14rem;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
      margin-bottom: 48px;
    }
    .button {
      width: 100%;
      margin-bottom: 16px;
    }
    .breadCrumb {
      width: 100%;
      margin-top: 160px;
    }
  }
}
