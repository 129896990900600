.topContainer {
  height: 100%;
  overflow: auto;
  text-align: center;
  .headerContainer {
    background-color: #fffbd6;
  }
  .typeContainer {
    text-align: center;
    margin-top: 12rem;
    font-weight: 700;
    .subTitle {
      font-size: 16rem;
    }
    .mainTitle {
      font-size: 26rem;
    }
    .subType {
      font-size: 14rem;
    }
  }
  .typeExplanation {
    padding: 15px;
    font-size: 14rem;
    font-weight: 700;
    .typeFuture {
      background-color: #ffe193;
      padding: 4rem 12rem;
      margin: 0;
      display: inline;
    }
  }
  .agendaContainer {
    padding: 16px;
    .agenda {
      padding: 16px 40px;
      border-radius: 16px;
      background: #fff;
      .agendaTitle {
        text-align: center;
        color: #747474;
        font-family: "Noto Sans CJK JP";
        font-size: 14rem;
        font-style: normal;
        font-weight: 700;
        line-height: 150%; /* 21px */
        letter-spacing: 0.56px;
      }
      .linkBlock {
        text-align: start;
        .link {
          cursor: pointer;
          color: #1784e9;
          font-size: 14rem;
          font-style: normal;
          font-weight: 500;
          line-height: 150%; /* 21px */
          letter-spacing: 0.56px;
          text-decoration-line: underline;
          text-decoration-style: solid;
          text-decoration-skip-ink: none;
          text-decoration-thickness: auto;
          text-underline-offset: auto;
          text-underline-position: from-font;
        }
        .link:not(:last-child) {
          margin-bottom: 12px;
        }
      }
    }
  }
  .barContainer {
    .bar {
      text-align: center;
      display: flex;
      margin: 14px 18px 0px 18px;
      .barLeft {
        flex: 1;
        color: white;
        font-weight: bold;
        font-size: 14px;
        text-align: center;
        height: 20px;
        margin: 0;
        padding: 0;
        border-top-left-radius: 24px;
        border-bottom-left-radius: 24px;
        background-color: #bfbfbf; // デフォルトの灰色
      }
      .barRight {
        flex: 1;
        color: white;
        font-weight: bold;
        font-size: 14px;
        text-align: center;
        height: 20px;
        margin: 0;
        padding: 0;
        border-top-right-radius: 24px;
        border-bottom-right-radius: 24px;
        background-color: #bfbfbf; // デフォルトの灰色
      }
      .barCenter {
        flex: 1;
        color: white;
        font-weight: bold;
        font-size: 14px;
        text-align: center;
        height: 20px;
        margin: 0;
        padding: 0;
        background-color: #bfbfbf; // デフォルトの灰色
        border-left: 2px dotted white;
        border-right: 2px dotted white;
      }
      .gray {
        background-color: #bfbfbf; // デフォルトの灰色
      }
      .orange {
        background-color: #eb6b43;
      }

      .green {
        background-color: #008e7e;
      }

      .blue {
        background-color: #5472da;
      }
    }
    .barTitle {
      font-weight: bold;
      font-size: 18px;
      text-align: center;
      margin-bottom: 0;
      margin-top: 40px;
    }
  }
  .messageBoxContainer {
    margin-top: 48rem;
    .messageBox {
      position: relative;
      background-color: #f9f7f3; /* 軽いグレーの背景色 */
      padding: 15px;
      margin: 5px 15px 0px;
      border-radius: 10rem 10rem 0 0;
    }
    .lockContainerBlur {
        position: absolute;  /* 親要素内で絶対配置 */
        bottom:2%;
        left:0;
        width: 100%;
        height: 40%;        
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 10rem;
        z-index: 1;
        background-color: rgba(255, 255, 255, 0.7); 
        backdrop-filter: blur(4px);
        -webkit-backdrop-filter: blur(15px); /* Safari対応 */  
        .lockText {
          margin-top: 8px;
          color: #f27400;
          font-size: 12rem;
          font-style: normal;
          font-weight: 700;
          line-height: 140%;
        }  
    }
    .messageBoxTitle {
      font-weight: bold;
      font-size: 24px;
      text-align: center;
      margin-bottom: 20px;
    }

    .messageBoxSubtitle {
      font-weight: bold;
      color: #008e7e; /* アイコンの色 */
      text-align: center;
      font-size: 20px;
      margin-top: 4px;
      margin-bottom: 14px;
    }

    .messageSubBoxContent {
      font-weight: 500;
      font-size: 14px;
      background-color: #ffffff;
      line-height: 150%;
      text-align: center;
      border-radius: 10rem;
      margin: 8px 4px;
    }

    .messageSubBoxTitle {
      text-align: center;
      font-size: 14px;
      margin: 0px;
      padding-top: 10px;
      padding-bottom: 0px;
    }

    .messageSubBoxTitleExplanation {
      margin: 8px 8px;
      padding-bottom: 10px;
      padding-top: 0px;
      font-size: 24px;
      font-weight: bold;
      color: #008e7e;
      line-height: 150%;
      vertical-align: middle;
      text-align: center;
    }

    .messageBoxNumber {
      margin: 8px 2px;
      padding-bottom: 10px;
      padding-top: 0px;
      font-size: 20px;
      font-weight: bold;
      color: #008e7e;
      line-height: 150%;
      vertical-align: middle;
      text-align: center;
    }

    .messageBoxNumberTitle {
      font-size: 14px;
      font-weight: bold;
      color: white;
      padding: 4px;
      background-color: #008e7e;
      line-height: 150%;
      text-align: center;
      margin: 10px 5px;
      vertical-align: middle;
    }

    .messageBoxContent {
      margin-top: 15px;
      font-weight: 500;
      font-size: 16px;
      line-height: 150%;
      text-align: left;
    }

    .registerMessageBoxContainer {
      display:flex;
      position: relative;
      padding: 15px;
      margin: 0px 15px 15px;
      border-radius: 0 0 10rem 10rem;
      align-items: center;
      background-color: #ffffff; /* 軽いグレーの背景色 */
      flex-direction: column;
      justify-content: center;
      .registerMessage {
        color: #F27400; 
        margin: 8px 8px;
        padding-bottom: 10px;
        padding-top: 0px;
        font-size: 16px;
        font-weight: bold;
        line-height: 150%;
        vertical-align: middle;
        text-align: left;
        }
      }  
  }

  .logoContainer {
    display: flex;
    align-items: center;
    width: calc(100% - 16rem);
    margin: 24rem auto;
    justify-content: space-around;
    .image {
      width: 88rem;
    }
    
    .bubbleContainer {
      background-color: #fffbd6;
      border: 2px solid #ffe193;
      padding: 12rem;
      position: relative;
      font-weight: 500;
      line-height: 150%;
      font-size: 14rem;
      border-radius: 16rem;
      margin-left: 10rem;
    }

    .bubbleContainer:before {
      content: "";
      position: absolute;
      top: 50%;
      left: -25px;
      margin-top: -5px;
      border: 15px solid transparent;
      border-right: 15px solid #fffbd6;
      border-top: 15px solid transparent;
      border-bottom: 15px solid transparent;
    }
  }
  .imageContainer {
    display: flex;
    flex-direction: column;
    .topImages {
      display: flex;
      justify-content: space-evenly;
      margin-bottom: 20px;
    }
  }

  .diagnosisButton {
    margin: 60rem 0 60rem;
    display: flex;
    justify-content: center;
  }

  .myPageButton {
    display: flex;
    justify-content: center;
    margin-bottom: 85rem;
  }
  .blockContainer {
    padding: 16px;
  }
  .lpContainer {
    background: #e8f0ee;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px;
    .lpTitle {
      background-color: #fff;
      padding: 3px 18px;
      border-radius: 24px;
      border: solid 2px #008e7e;
      color: #000;
      text-align: center;
      font-size: 18rem;
      font-style: normal;
      font-weight: 700;
      line-height: 150%;
      .strong {
        position: relative;
        display: inline-block;
        color: transparent;
        .text {
          position: absolute;
          color: #000;
          top: 0;
          right: 0;
          z-index: 1;
        }
        .line {
          position: absolute;
          width: 72px;
          background-color: rgba(248, 181, 0, 0.5);
          height: 9px;
          right: 0;
          bottom: 3px;
          border-radius: 1px;
        }
      }
    }
    .lpHeader {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin: 12px 0;
      .lpHeader1 {
        color: #008e7e;
        text-align: center;
        font-size: 16rem;
        font-style: normal;
        font-weight: 700;
        line-height: 150%; /* 24px */
        letter-spacing: 0.64px;
      }
      .lpHeader2 {
        color: #000;
        text-align: center;
        font-size: 24rem;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
      }
      .lpSubheader {
        color: #000;
        font-size: 20rem;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
      }
    }
    .lpImageContainer {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      .lpImage {
        margin: 8px;
        width: calc(50% - 16px);
      }
      .lpImageLarge {
        margin: 8px;
        width: calc(100% - 16px);
      }
    }
  }
  .registerContainer {
    background: #fffbd6;
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .registerTitle {
      margin-bottom: 20px;
      color: #000;
      font-size: 24rem;
      font-style: normal;
      font-weight: 700;
      line-height: 150%;
    }
    .registerHeader {
      color: #000;
      width: 100%;
      text-align: start;
      font-size: 14rem;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
    }
    .registerForm {
      width: 100%;
      text-align: start;
      margin: 40px 0;
    }
    .registerCaption {
      margin-top: 8px;
      color: #000;
      font-size: 14rem;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
      .link {
        cursor: pointer;
        color: #1784e9;
        font-size: 14rem;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 21px */
        letter-spacing: 0.56px;
        text-decoration-line: underline;
        text-decoration-style: solid;
        text-decoration-skip-ink: none;
        text-decoration-thickness: auto;
        text-underline-offset: auto;
        text-underline-position: from-font;
      }
    }
  }
}