.container {
  font-size: 12rem;
  background-color: white;
  border-radius: 12rem;
  height: 100%;
  .cardHeader {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30rem 20rem 0;
    .images {
      width: 80rem;
      height: 80rem;
    }
  }
  .content {
    margin-top: 16rem;
    padding: 0 20rem 20rem;
    font-size: 14rem;
    font-weight: 700;
    text-align: center;
  }
}
