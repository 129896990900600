.container {
  align-items: center;
  .quizContainer {
    padding: 16rem;
  }
  .actionSubtitle {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #747474;
    font-size: 14rem;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 21px */
    letter-spacing: 0.56rem;
    margin-top: 12rem;
  }
  .actionTitle {
    color: #000;
    text-align: center;
    /* 24 - Bold */
    font-size: 24rem;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 36px */
    letter-spacing: 0.96rem;
    margin-top: 8rem;
  }
  .badgeContainer {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    .acquiredBadgeType {
      margin-top: 12rem;
      width: 128rem;
      height: 128rem;
    }
  }
  .button {
    display: flex;
    justify-content: center;
  }
}
.breadCrumb {
  margin-top: auto;
}
