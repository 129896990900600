.container {
  min-height: calc(100% - 250rem);
  .body {
    display: flex;
    flex-direction: column;

    height: calc(100% - 64rem);
    .avatar {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 24rem 0;
      padding: 0 16rem;
    }
    .profile {
      padding: 0 16rem;
      .title {
        font-size: 14rem;
        font-weight: 700;
        margin-left: 16rem;
        margin-bottom: 8rem;
      }
    }
    .breadCrumb {
      margin-top: auto;
    }
  }
}
