.container {
  .body {
    padding: 16px;
    .title {
      margin: 24px 0;
      color: #000;
      text-align: center;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%; /* 36px */
      letter-spacing: 0.96px;
    }
    .caption {
      color: #747474;
      font-size: 14rem;
      font-style: normal;
      font-weight: 700;
      line-height: 150%; /* 21px */
      letter-spacing: 0.56px;
      margin-top: 48px;
      margin-bottom: 8px;
    }
    .courseContainer {
      display: flex;
      margin: 16px 0;
      .card {
        width: calc(50% - 8px);
        .image {
          width: 100%;
        }
      }

      .card:first-child {
        margin-right: 8px;
      }
      .card:last-child {
        margin-left: 8px;
      }
    }
    .timelineContainer {
      margin-top: 32px;
    }
  }
}
