.container {
  .outerCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 16rem;
    padding: 15px;
    .title {
      font-weight: bold;
      font-size: 24px;
      text-align: center;
      line-height: 140%;
      position: relative;
      width: 100%;
      .redo {
        position: absolute;
        top: 0;
        right: 0;
        height: 22rem;
        color: #fff;
        border-radius: 12px;
        background-color: #444;
        display: flex;
        align-items: center;
        font-size: 12rem;
        padding: 0rem 8rem;
      }
    }
    .logoContainer {
      margin-top: 24rem;
    }
    .card {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 8rem;
      margin-top: 20rem;
      border-radius: 10rem;
      background-color: #fff;
      width: calc(100% - 16rem);
      .cardTitle {
        color: #000;
        font-size: 14rem;
        font-weight: 500;
        line-height: 140%;
      }
      .cardBody {
        width: 100%;
        margin-top: 8rem;
        .block {
          display: flex;
          align-items: center;
          justify-content: center;
          .label {
            padding: 1rem 5rem;
            border-radius: 2rem;
            margin-bottom: 5rem;
            margin-right: 4rem;
            background-color: #008e7e;
            color: #fff;
            font-size: 14rem;
            font-weight: 700;
            line-height: 140%;
          }
          .value {
            color: #008e7e;
            font-size: 24rem;
            font-weight: 700;
            line-height: 140%;
            letter-spacing: 3.84rem;
          }
          .unit {
            color: #008e7e;
            font-size: 16rem;
            font-style: normal;
            font-weight: 700;
            line-height: 140%;
          }
        }
        .breakdownArea {
          padding: 0 16rem;
          .breakdpwnTop {
            font-size: 14rem;
            font-weight: 700;
            margin-top: 8rem;
            padding: 10rem;
            color: white;
            border-radius: 24rem;
            background-color: rgba(0, 142, 126, 1);
            display: flex;
            align-items: center;
            .breakdownTitle {
              margin-left: 10rem;
            }
          }
          .breakdown {
            font-size: 12rem;
            font-weight: 700;
            padding: 16rem 0;
            background-color: #edecea;
            border-bottom-left-radius: 16rem;
            border-bottom-right-radius: 16rem;
            .child {
              display: flex;
              justify-content: space-between;
              align-items: start;
              padding: 0 16rem 16rem;
              border-bottom: 1px solid rgba(116, 116, 116, 1);
              margin-top: 16rem;
              .childNo {
                background-color: rgba(0, 142, 126, 1);
                color: #fff;
                font-size: 14rem;
                padding: 1rem 5rem;
              }
              .feeList {
                width: 78%;
                .feeWrapper {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  margin-bottom: 15rem;
                  .feeName {
                    min-width: 70rem;
                    max-width: 87rem;
                    text-align: left;
                  }
                  .fee {
                    display: flex;
                    align-items: baseline;
                    .value {
                      color: #333;
                      font-size: 20rem;
                      font-weight: 700;
                      line-height: 140%;
                      letter-spacing: 3.84rem;
                      word-break: break-word;
                      text-align: end;
                    }
                    .unit {
                      color: #333;
                      font-size: 16rem;
                      font-style: normal;
                      font-weight: 700;
                      line-height: 140%;
                      width: 33rem;
                    }
                  }
                }
                .feeWrapper:last-child {
                  margin-bottom: 0;
                }
              }
            }
            .child:first-child {
              margin-top: 0;
            }
            .child:last-child {
              border-bottom: none;
              padding-bottom: 0;
            }
          }
        }
      }
    }
  }
}
