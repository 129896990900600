button {
  padding: 12rem 16rem;
  font-size: 16rem;
  font-weight: 700;
  border: none;
  border-radius: 24rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #0a0d10;
  min-height: 47px;
  position: relative;
}
.orange {
  background-color: #fabf00;
}

.redOrange {
  background-color: #f27400;
  color: white;
}

.black {
  background-color: #444444;
  color: white;
}

.white {
  background-color: #fff;
  color: #000;
}

.green {
  background-color: rgba(0, 142, 126, 1);
  color: white;
}

.leftIcon {
  margin-right: 3rem;
}
.rightIcon {
  position: absolute;
  bottom: 15rem;
  right: 15rem;
}

.leftIcon,
.rightIcon {
  height: 18rem;
}

.disabled {
  opacity: 0.4;
}
