.container {
  background-color: #f5f3f0;
  border-radius: 16rem;
  padding: 10rem 10rem 32rem 10rem;
  position: relative;
  .frameContent {
    background-color: white;
    padding: 10rem;
    border-radius: 10rem;
  }
  .frameTitle {
    text-align: center;
    margin-top: 8rem;
    font-weight: 700;
    font-size: 14rem;
  }
  .vectorIcon {
    position: absolute;
    color: black;
    width: 32rem;
    background-color: white;
    border-radius: 50%;
    height: 32rem;
    line-height: 32rem;
    display: flex;
    left: 50%;
    transform: translate(-50%, 50%);
    bottom: 0;
  }
}
