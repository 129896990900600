.container {
  padding: 16px;
  .body {
    width: 100%;
    .title {
      margin: 40px 0;
      text-align: center;
      color: #000;
      font-size: 24rem;
      font-style: normal;
      font-weight: 700;
      line-height: 140%; /* 33.6px */
      letter-spacing: 0.96px;
    }
    .form {
      margin-bottom: 24px;
    }
    .button {
      margin-bottom: 100px;
    }
  }
}

.breadCrumb {
  margin-top: auto;
}

