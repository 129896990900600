.container {
  display: flex;
  flex-direction: column;
  font-size: 16rem;
  line-height: 150%;
  position: relative;
  .label {
    font-size: 14rem;
  }
  .subLabel {
    font-size: 12rem;
    color: #747474;
  }
  .textarea {
    padding: 12px 16px;
    border-radius: 16px;
    border: 1px solid var(--PrimitiveColors-Gray-03, #bfbfbf);
    outline: none;
    font-size: 16rem;
    &::placeholder {
      color: #bfbfbf;
    }
  }
}
