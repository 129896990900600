.container {
  padding: 0 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .headerImage {
    margin: 0 auto;
    padding: 17px 0;
  }
  .teacherImage {
    width: 140px;
  }
  .title {
    margin-top: 16px;
    margin-bottom: 24px;
    color: #000;
    font-size: 24rem;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
  }
  .email {
    width: 100%;
    height: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    color: #000;
    font-size: 16rem;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
  }
  .text {
    margin-top: 20px;
    color: #000;
    font-size: 14rem;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    .mailAddress {
      text-decoration-line: underline;
      text-decoration-style: solid;
      text-decoration-skip-ink: none;
      text-decoration-thickness: auto;
      text-underline-offset: auto;
      text-underline-position: from-font;
    }
  }
  .captionTitle {
    color: #e91717;
    font-size: 14rem;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    margin-top: 24px;
    margin-bottom: 16px;
  }
  .caption {
    color: #e91717;
    font-size: 14rem;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    margin-bottom: 64px;
    .link {
      cursor: pointer;
      color: #1784e9;
      font-size: 14rem;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
      text-decoration-line: underline;
      text-decoration-style: solid;
      text-decoration-skip-ink: none;
      text-decoration-thickness: auto;
      text-underline-offset: auto;
      text-underline-position: from-font;
    }
  }
}
