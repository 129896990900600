.container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  font-size: 12rem;
  font-weight: 500;
  line-height: 160%;
  margin: 16rem;
  width: calc(100% - 32rem);
  .normal {
    color: #747474;
  }
  .strong {
    color: #000;
  }
  .icon {
    margin: 0 8rem;
    margin-bottom: 2rem;
    width: 4rem;
    height: 6rem;
  }
}
