.container {
  .title {
    font-size: 24rem;
    text-align: center;
    font-weight: 700;
    margin-top: 24rem;
  }
  .badgeArea {
    display: flex;
    justify-content: center;
    gap: 5rem;
    max-width: 90%;
    margin: auto;
    font-size: 18rem;
    color: #008e7e;
    font-weight: 700;
    text-align: center;
    margin-top: 12rem;
    .badgeCircle {
      position: relative;
      width: 36rem;
      height: 36rem;
    }
    .badgeImg {
      position: absolute;
      top: 0;
      left: 0;
    }
    .inCircle {
      background-color: rgb(255, 247, 212);
      width: 28rem;
      height: 28rem;
      position: absolute;
      border-radius: 50%;
      top: 4rem;
      right: 4rem;
    }
  }
  .cardArea {
    display: flex;
    justify-content: space-between;
    gap: 15rem;
    padding: 16rem 16rem 32rem 16rem;
    margin-top: 12rem;
    flex-wrap: wrap;
    .caption {
      color: #000;
      font-size: 14rem;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
      width: 100%;
      text-align: center;
      margin: 24px 0;
    }
    .cardWrapper {
      width: calc(50% - 8rem);
      height: 192rem;
    }
  }
}
.breadCrumb {
  margin-top: auto;
}
