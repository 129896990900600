.container {
  margin-top: 24rem;
  margin-bottom: 38rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  .title {
    text-align: center;
    font-size: 24rem;
    font-weight: 700;
    line-height: 150%; /* 36px */
    letter-spacing: 0.96rem;
  }

  .rank {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 4rem;
    color: #fff;
    font-size: 16rem;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    border-radius: 12rem;
    width: 100rem;
    height: 24rem;
  }
  .beginer {
    background-color: #34b1a3;
  }
  .bronze {
    background-color: #a17162;
  }
  .silver {
    background-color: #7985a4;
  }
  .gold {
    background-color: #be962f;
  }
  .platinum {
    background-color: #47a5d9;
  }
}
