.container {
  padding: 8rem;
  color: #000;
  font-size: 10rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  border-radius: 10rem;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .header {
    display: flex;
    width: calc(100% - 32rem);
    padding: 16rem;
    .legend:not(:first-child) {
      margin-left: 8rem;
    }
    .legend {
      display: flex;
      align-items: center;
      .label {
        width: 32rem;
        height: 8rem;
        margin-right: 4rem;
      }
      .education {
        background-color: #f27400;
      }
      .revenue {
        background-color: #34b1a3;
      }
      .expense {
        background-color: #fabf00;
      }
      .labelLine {
        width: 32rem;
        height: 4rem;
        margin-right: 4rem;
        border-radius: 4rem;
      }
      .cash {
        background-color: #1784e9;
      }
      .asset {
        background-color: #747474;
      }

      .title {
        color: #000;
        font-size: 10rem;
        font-weight: 500;
        line-height: 140%;
      }
    }
  }
  .header:not(:first-child) {
    padding-top: 0;
  }
  .chart {
    margin-left: -20rem;
  }
}

recharts-surface ::ng-deep .recharts-surface {
  overflow: visible;
}
