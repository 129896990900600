.container {
  min-height: calc(100% - 250rem);
  .body {
    display: flex;
    flex-direction: column;
    padding: 0 16rem;
    height: calc(100% - 64rem);
    .avatar {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 24rem 0;
    }
    .profile {
      .title {
        font-size: 14rem;
        font-weight: 700;
        margin-left: 16rem;
        margin-bottom: 8rem;
      }
    }
    .buttonBlock {
      margin-top: 40rem;
      width: calc(100% - 32rem);
      padding: 16rem;
      .button {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 48rem;
        border-radius: 24rem;
        background-color: #fabf00;
        text-align: center;
        font-size: 16rem;
        font-weight: 700;
        line-height: 150%;
      }
      .cancel {
        margin-top: 24rem;
        background-color: #444;
        color: #fff;
      }
    }
    .breadCrumb {
      margin-top: auto;
    }
  }
}
