.container {
  .parentContainer {
    background-color: white;
    padding: 16rem 12rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: calc(75rem - 32rem);
    .label {
      color: rgba(0, 142, 126, 1);
      font-size: 14rem;
      font-style: normal;
      font-weight: 700;
    }
    .right {
      display: flex;
      align-items: center;
    }
    .completeIcon {
      padding-right: 10rem;
      padding-bottom: 3rem;
    }
    .message {
      font-size: 12rem;
      font-weight: 700;
      color: rgba(116, 116, 116, 1);
      margin-right: 8rem;
    }
  }
  .childrenContainer {
    padding: 0 16rem;
    margin-top: 16rem;
    .childContainer {
      margin-bottom: 12rem;
    }
  }
}
