.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14rem 16rem;
  .img {
    display: block;
  }
  .headerIcon {
    width: 24rem;
    height: 24rem;
  }
}
