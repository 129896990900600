.container {
  width: 100%;
  font-size: 16rem;
  line-height: 150%;
  font-weight: 500;
  letter-spacing: 0.64rem;
  display: flex;
  flex-direction: column;
  .teacherCommentWrapper {
    padding: 0 16rem;
  }
  .buttonArea {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12rem;
  }
  .listArea {
    padding: 0 16rem 64rem;
    margin-top: 40rem;
    .parentContainer {
      width: 100%;
      background-color: #fff;
      font-size: 14rem;
      .parentLabel {
        padding: 16px 8px 16px 20px;
        display: flex;
        align-items: center;
      }

      .childContainer {
        background-color: #f8f8f8;
        padding: 16px 28px 16px 24px;
        display: flex;
        align-items: center;
        .label {
          display: block;
          color: var(--PrimitiveColors-Black, #000);
          font-size: 14px;
          font-weight: 500;
          line-height: 150%; /* 21px */
          letter-spacing: 0.56px;
        }
        .valueWrapper {
          margin-left: auto;
          display: flex;
          align-items: center;
          justify-content: end;
          width: 100rem;
          .value {
            color: #747474;
            font-size: 12px;
            font-weight: 500;
            line-height: 150%; /* 18px */
            letter-spacing: 0.48px;
          }
        }
        .icon {
          height: 20px;
          width: 20px;
        }
      }
      .childContainer {
        border-top: solid 1px #d8d8d8;
      }
    }
    .parentContainer:not(:first-child) {
      border-top: solid 1px #d8d8d8;
    }
    .parentContainer:first-child {
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
    }
    .parentContainer:last-of-type {
      border-bottom-left-radius: 16px;
      border-bottom-right-radius: 16px;
    }
  }
  .buttonArea {
    margin-top: 20rem;
    display: flex;
    justify-content: space-evenly;
    padding: 0 16rem;
    gap: 16rem;
  }
  .link {
    margin-top: 20rem;
    cursor: pointer;
    color: #1784e9;
    font-size: 14rem;
    font-weight: 500;
    text-decoration-line: underline;
    text-align: center;
  }
  .breadCrumb {
    margin-top: auto;
  }
}
