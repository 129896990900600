@use "./static/scss/common.scss" as common;
html {
  font-size: 1px;
}

@media screen and (max-width: 1280px) {
  html {
    font-size: 1px;
  }
}

html {
  height: 100%;
  width: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;

  font-family: "Noto Sans JP","sans-serif";
}

body,
#root {
  max-width: common.$max-width;
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: #ece6dc;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
