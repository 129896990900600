.container {
  font-size: 12rem;
  background-color: white;
  border-radius: 12rem;
  padding: 20rem 0 24rem;
  .bannerTop {
    margin-top: 20rem;
    .teacherComment {
      width: calc(100% - 24rem);
      margin: auto;
    }
  }
  .image1 {
    display: flex;
    justify-content: center;
  }
  .image2 {
    margin-top: 20rem;
    display: flex;
    justify-content: center;
  }
  .image3 {
    margin-top: 20rem;
    display: flex;
    justify-content: center;
  }
  .img {
    width: 90%;
  }
  .buttonArea {
    display: flex;
    justify-content: center;
    margin-top: 20rem;
  }
}
