.container {
  .question {
    padding: 12rem 16rem;
    .header {
      position: relative;
      display: flex;
      align-items: center;
    }
    .centerGroup {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      align-items: center;
      }
      .index {
        color: #008e7e;
        font-size: 16rem;
        font-style: normal;
        font-weight: 700;
        line-height: 140%;
      }
      .slash {
        margin: 0 4rem;
      }
      .total,
      .slash {
        color: #008e7e;
        font-size: 12rem;
        font-style: normal;
        font-weight: 700;
        line-height: 140%;
      }
      .rate {
        margin-left: auto;  // フレックスの流れでは右に配置される
        color: #747474;
        font-size: 12rem;
        font-weight: 700;
        line-height: 140%;
        position: relative;
        z-index: 1;
      }
    
    .text {
      text-align: center;
      margin-top: 8rem;
      color: #000;
      font-size: 20rem;
      font-style: normal;
      font-weight: 700;
      line-height: 140%; /* 28px */
      letter-spacing: 0.8rem;
    }
    .quizImage {
      position: absolute;
      z-index: 999;
      top: 150rem;
      left: 50%;
      transform: translateX(-50%);
      width: calc(100% - 32rem);
    }
    .buttonBlock {
      margin-top: 20rem;
      display: flex;
      align-items: center;
      justify-content: center;
      .button {
        background-color: #fff;
        margin: 0 4rem;
        width: calc(50% - 8rem);
        padding: 12rem 16rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 24rem;
        .image {
          width: 24rem;
          height: 24rem;
        }
      }
      .correct {
        background-color: #aad6ff;
      }
      .incorrect {
        background-color: #bfbfbf;
      }
    }
  }
  .answer {
    background-color: rgba(255, 251, 214, 1);
    padding: 20rem 16rem 32rem;
    position: relative;
    .headText {
      font-size: 12rem;
      background-color: rgba(0, 142, 126, 1);
      color: #fff;
      border-radius: 34rem;
      text-align: center;
      padding: 4rem;
    }
    .summary {
      display: flex;
      align-items: center;
      // position: relative;
      margin-top: 14rem;
      .label {
        font-size: 20rem;
        font-style: normal;
        font-weight: 700;
        line-height: 140%; /* 28px */
        letter-spacing: 0.8rem;
      }
      .text {
        // position: absolute;
        margin-left: auto;
        margin-right: calc(50% - 45rem);
        color: #000;
        font-size: 20rem;
        font-style: normal;
        font-weight: 700;
        line-height: 140%; /* 28px */
        letter-spacing: 0.8rem;
      }
    }
    .body {
      margin-top: 14rem;
      color: #000;
      font-size: 14rem;
      font-style: normal;
      font-weight: 500;
      line-height: 150%; /* 21px */
      letter-spacing: 0.56rem;
    }
    .resultImageArea {
      position: absolute;
      bottom: -50px;
      right: 16px;
      .img {
        width: 88rem;
      }
    }
  }
  .thinkingImageArea {
    margin-top: 40rem;
    display: flex;
    justify-content: center;
    .img {
      width: 100rem;
    }
  }
}
