.container {
  width: 100%;
  font-size: 16rem;
  line-height: 150%;
  font-weight: 500;
  letter-spacing: 0.64rem;
  display: flex;
  flex-direction: column;
  height: 100%;
  .body {
    padding: 0 16rem;
    .block {
      .listWithoutPad {
        margin-bottom: 16rem;
        .breakdownArea {
          padding: 0 16rem;
          .breakdpwnTop {
            font-size: 14rem;
            font-weight: 700;
            margin-top: 8rem;
            padding: 10rem;
            color: white;
            border-top-left-radius: 24rem;
            border-top-right-radius: 24rem;
            background-color: rgba(0, 142, 126, 1);
            display: flex;
            align-items: center;
            .breakdownTitle {
              margin-left: 10rem;
            }
          }
          .parentContainer {
            width: 100%;
            border-radius: 16px;
            background-color: #fff;
            font-size: 14rem;
            .parentLabel {
              background-color: #e2f9ef;
              padding: 16px 8px 16px 20px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              align-items: center;
              .tagContainer {
                margin-left: auto;
                margin-top: 8rem;
                margin-right: 16rem;
                border: 1px solid var(--PrimitiveColors-Orange-01, #f27400);
                font-size: 14rem;
                font-style: normal;
                font-weight: 700;
                line-height: 140%; /* 19.6px */
                color: #f27400;
                display: flex;
                padding: 0 6rem;
                align-items: center;
                .tag {
                  margin-left: 2rem;
                }
              }
            }
            .childrenContainer {
              .childrenLabel {
                padding: 16px 8px 16px 20px;
                display: flex;
                align-items: center;
              }
              .childContainer {
                background-color: #f8f8f8;
                padding: 16px 16px 16px 24px;
                display: flex;
                align-items: center;
                .label {
                  display: block;
                  color: var(--PrimitiveColors-Black, #000);
                  font-size: 14px;
                  font-weight: 500;
                  line-height: 150%; /* 21px */
                  letter-spacing: 0.56px;
                }
                .value {
                  color: #747474;
                  font-size: 12px;
                  font-weight: 500;
                  line-height: 150%; /* 18px */
                  letter-spacing: 0.48px;
                  margin-left: auto;
                  text-align: right;
                  width: 95rem;
                }
                .icon {
                  height: 20px;
                  width: 20px;
                }
              }
              .childContainer {
                border-top: solid 1px #d8d8d8;
              }
            }
          }
          .parentContainer:not(:first-child) {
            border-top: solid 1px #d8d8d8;
          }
        }
      }
    }
    .teacherCommentWrapper {
      padding: 0 16rem;
    }
  }
  .breadCrumb {
    margin-top: auto;
  }
}
