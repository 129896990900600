.container {
  padding-bottom: 80rem;
  .progressBarContainer {
    padding: 0 16rem;
    margin-top: 24rem;
    .progressContainer {
      position: relative;
      height: 8rem;
      background: rgba(255, 255, 255, 1);
      border-radius: 10rem;
      .progressBar {
        height: 100%;
        background-color: #008e7e;
        border-radius: 10rem;
        transition: width 0.3s;
      }

      .startNumber {
        position: absolute;
        top: 50%; // 縦方向の位置を中央に変更
        left: -8rem; // 横方向の位置を中央に変更
        transform: translateY(-50%);
        background-color: #008e7e;
        border-radius: 50%;
        width: 22rem;
        height: 22rem;
        text-align: center;
        line-height: 22rem;
        font-size: 14rem;
        font-weight: bold;
        color: white;
        border: 2rem solid #008e7e; // 青い境界線を追加
        z-index: 2; //重なった時はStartが優先
      }

      .endNumber {
        position: absolute;
        top: 50%;
        right: -6rem;
        transform: translateY(-50%);
        background-color: white;
        border-radius: 50%;
        width: 22rem;
        height: 22rem;
        text-align: center;
        line-height: 22rem;
        font-size: 14rem;
        font-weight: bold;
        border: 2rem solid #ffffff; // 青い境界線を追加
        z-index: 1; //重なった時はStartが優先
      }
    }
  }
  .header {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 16rem;
    flex-direction: column;
  }
  .centerGroup {
    display: flex;
    align-items: center;

    .index {
      color: #008e7e;
      font-size: 16rem;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;
    }
    .slash {
      margin: 0 4rem;
    }
    .total,
    .slash {
      color: #008e7e;
      font-size: 12rem;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;
    }
  }
  .title {
    font-weight: 700;
    text-align: center;
    margin-top: 8rem;
    .subCategoryName {
      font-size: 14rem;
      color: #747474;
    }
    .itemName {
      font-size: 24rem;
    }
  }
  .teacherCommentWrapper {
    padding: 0 16rem;
  }
  .sliderArea {
    margin: 40rem 0;
    .sliderContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      .border {
        height: 4rem;
        width: 58rem;
        background-color: white;
      }
      .round {
        width: 32rem;
        height: 32rem;
        background-color: white;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        .upperLabel {
          font-size: 12rem;
          top: -40rem;
          position: absolute;
          background-color: #444444;
          color: white;
          padding: 4rem;
          font-weight: 700;
          width: 45rem;
          height: 20rem;
          text-align: center;
          border-radius: 4rem;
        }
        .innerRound {
          background: white;
          width: 12rem;
          height: 12rem;
          border-radius: 50%;
        }
        .lowerLabel {
          font-size: 12rem;
          top: 40rem;
          position: absolute;
          font-weight: 700;
          width: 85rem;
          height: 20rem;
          text-align: center;
          color: #747474;
        }
      }
    }
  }
  .inputArea {
    padding: 0 16rem;

    margin-bottom: 24rem;
    .required {
      font-size: 12rem;
      background-color: #f27400;
      color: white;
      font-weight: 700;
      padding: 5rem;
      border-radius: 4rem;
    }
    .optional {
      font-size: 12rem;
      background-color: #747474;
      color: white;
      font-weight: 700;
      padding: 5rem;
      border-radius: 4rem;
    }
    .itemName {
      font-size: 12rem;
      font-weight: 500;
      margin-left: 10rem;
    }
    .input {
      margin-top: 8rem;
      position: relative;
    }
    .unit {
      position: absolute;
      bottom: 40rem;
      left: 250rem;
      font-size: 14px;
      font-weight: 500;
    }
  }
  .buttonArea {
    margin-top: 20rem;
    display: flex;
    justify-content: space-evenly;
    padding: 0 16rem;
    gap: 16rem;
  }
  .link {
    margin-top: 20rem;
    cursor: pointer;
    color: #1784e9;
    font-size: 14rem;
    font-weight: 500;
    text-decoration-line: underline;
    text-align: center;
  }
}
.breadCrumb {
  margin-top: auto;
}
