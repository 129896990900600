.headerSub {
  display: flex;
  align-items: center;
  width: calc(100% - 16rem);
  margin: 24rem auto;
  justify-content: space-around;
}

.heading {
  background-color: #008e7e;
  color: white;
  font-size: 14rem;
  margin: 24rem 0;
  padding: 4rem;
  border-radius: 4rem;
  line-height: 150%;
  font-weight: 700;
  display: flex;
  align-items: center;
  position: relative;
  .headingTitle {
    margin-left: auto;
    margin-right: auto;
  }
}

.headingNo {
  position: absolute;
  left: 4rem;
}

.headingNumerator {
  font-size: 20rem;
}

.headingDenominator {
  font-size: 12rem;
}

.selectArea {
  width: calc(100% - 32rem);
  margin: auto;
  padding-bottom: 48rem;
}

.inputWrapper {
  margin-bottom: 32rem;
  .buttonArea {
    margin-top: 20rem;
  }
}

.input {
  font-weight: 800;
  text-align: left;
  margin-top: 12rem;
}

.buttonArea {
  display: flex;
  justify-content: center;
}

.removeChild {
  margin-bottom: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  cursor: pointer;
  .removeText {
    margin-left: 8px;
    color: #000;
    font-size: 14rem;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 21px */
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-decoration-skip-ink: none;
    text-decoration-thickness: auto;
    text-underline-offset: auto;
    text-underline-position: from-font;
  }
}
