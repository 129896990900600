.blur {
  filter: blur(3px);
}
.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .header {
    width: 100%;
  }
  .body {
    padding: 16rem;
    width: calc(100% - 32rem);
    .lockContainer {
      margin-top: 16rem;
      .yellow {
        background-color: #fffbd6;
      }
      .blue {
        background-color: #e8f0ee;
      }
      .card {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 12rem;
        border-radius: 16rem;
        width: calc(100% - 24rem);
        .title {
          color: #000;
          font-size: 16rem;
          font-weight: 700;
          line-height: 140%;
          .caption {
            color: #000;
            font-size: 14rem;
            font-style: normal;
            font-weight: 700;
            line-height: 140%;
          }
        }
        .icon {
          height: 40rem;
          width: 40rem;
          margin: 8rem 0;
        }
        .caption {
          color: #747474;
          font-size: 12rem;
          font-weight: 700;
          line-height: 140%;
        }
      }
    }
    .lifeplanScoreContainer,
    .educationFinanceContainer,
    .retirementFinanceContainer {
      margin-top: 16rem;
      .outerCard {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 16rem;
        background-color: #e8f0ee;
        padding: 12rem;
        .title {
          color: #000;
          font-size: 16rem;
          font-weight: 700;
          line-height: 140%;
          position: relative;
          width: 100%;
          display: flex;
          justify-content: center;
          .redo {
            position: absolute;
            top: 0;
            right: 0;
          }
        }
        .card {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding: 8rem;
          margin-top: 8rem;
          border-radius: 10rem;
          background-color: #fff;
          width: 100%;
          .cardTitle {
            color: #000;
            font-size: 14rem;
            font-weight: 500;
            line-height: 140%;
          }
          .cardBody {
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            width: 100%;
            margin-top: 8rem;
            .block {
              display: flex;
              align-items: center;
              .label {
                padding: 1rem 5rem;
                border-radius: 2rem;
                margin-right: 4rem;
                background-color: #008e7e;
                color: #fff;
                font-size: 14rem;
                font-weight: 700;
                line-height: 140%;
              }
              .value {
                color: #008e7e;
                font-size: 24rem;
                font-weight: 700;
                line-height: 140%;
                letter-spacing: 3.84rem;
                margin-bottom: 5rem;
              }
              .unit {
                color: #008e7e;
                font-size: 16rem;
                font-style: normal;
                font-weight: 700;
                line-height: 140%;
              }
            }
          }
        }
      }
    }

    .lifeplanScoreContainer {
      .outerCard {
        background-color: #fffbd6;
        .card {
          .cardBody {
            flex-direction: column;
            .block {
              .normal {
                color: #1784e9;
              }
              .notSoGood {
                color: #f27400;
              }
              .bad {
                color: #fd3939;
              }
            }
          }
        }
      }
    }

    .teacherComment {
      margin-top: 16rem;
    }
    .expenseContainer {
      margin-top: 32rem;
      width: calc(100% - 20rem);
      padding: 24rem 10rem;
      background-color: rgb(248, 247, 243);
      border-radius: 16rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      .header {
        .title {
          color: #000;
          text-align: center;
          font-size: 20rem;
          font-style: normal;
          font-weight: 700;
          line-height: 140%;
        }
        .caption {
          margin-top: 12rem;
          margin-bottom: 12rem;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .yeildBlock {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 14rem;
          font-weight: 700;
          .form {
            width: 250rem;
          }
          .redo {
            margin-left: 16rem;
            margin-bottom: 10rem;
          }
        }
      }
      .chart {
        width: 100%;
      }
      .expenseSummaryContainer {
        margin-top: 32rem;
        border-radius: 10rem;
        background-color: #fff;
        position: relative;
        width: 100%;
        .header {
          padding: 4rem;
          border-radius: 10rem 10rem 0 0;
          background-color: #747474;
          color: #fff;
          text-align: center;
          font-size: 14rem;
          font-weight: 700;
          line-height: 140%;
        }
        .line {
          padding: 8rem 16rem;
          display: flex;
          align-items: center;
          border-top: solid 1rem #d8d8d8;
          .text {
            color: #000;
            font-size: 14rem;
            font-weight: 700;
            line-height: 140%;
          }
          .value {
            margin-left: auto;
            color: #747474;
            font-size: 14rem;
            font-weight: 700;
            line-height: 140%;
          }
        }
      }
      .expenseSummaryContainer:before {
        content: "";
        position: absolute;
        top: -28rem;
        left: calc(50% - 14rem);
        border: 14rem solid transparent;
        border-bottom: 14rem solid #747474;
      }
      .advice {
        margin-top: 32rem;
        color: var(--PrimitiveColors-Black, #000);
        font-size: 16rem;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        .text:not(:first-child) {
          margin-top: 16rem;
        }
      }
    }
    .diagnosisContainer {
      margin-top: 16rem;
    }
    .bannerCounselling {
      margin-top: 16rem;
      margin-bottom: 80rem;
      width: 100%;
    }
    .lifeplanAchievementContainer {
      margin-top: 32rem;
      padding: 32rem;
      background-color: #e8f0ee;
      display: flex;
      flex-direction: column;
      align-items: center;
      .title {
        color: #000;
        font-size: 20rem;
        font-style: normal;
        font-weight: 700;
        line-height: 140%;
      }
      .lifeplanAchievementImage {
        margin: 32rem 0;
      }
      .advice {
        color: #000;
        font-size: 16rem;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        .text:not(:first-child) {
          margin-top: 16rem;
        }
      }
    }
    .breadCrumb {
      margin-top: auto;
    }
  }
}

.lockText {
  color: #747474;
  font-size: 14rem;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  display: flex;
  align-items: center;
}
.lockText::before {
  content: url(../../static/images/lock-icon.svg);
}

.redo {
  height: 22rem;
  color: #fff;
  border-radius: 12px;
  background-color: #444;
  display: flex;
  align-items: center;
  font-size: 12rem;
  padding: 0rem 8rem;
  img {
    margin-right: 4rem;
  }
}
