.container {
  .imageContainer {
    display: flex;
    justify-content: center;
    .headerImage {
      margin: 0 auto;
      padding: 17px 0;
    }
  }
  .body {
    padding: 16px;
    .teacherImageContainer {
      display: flex;
      justify-content: center;
      margin: 24px 0;
      .teacherImage {
        height: 162px;
      }
    }
    .title {
      margin-bottom: 16px;
      color: #000;
      text-align: center;
      font-size: 24rem;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;
    }
    .caption {
      margin-bottom: 40px;
      color: #000;
      font-size: 14rem;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
    }
    .button {
      margin-bottom: 180px;
    }
  }
}
