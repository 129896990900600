.userInfo {
  display: flex;
  align-items: center;
  .badgeWrapper {
    position: relative;
    .badge {
      width: 100rem;
      height: 100rem;
    }
    .badgeName {
      font-size: 15rem;
      position: absolute;
      width: 64px;
      background: #6bd2c0;
      height: 64px;
      border-radius: 50%;
      line-height: 50%;
      text-align: center;
      top: 25rem;
      right: 18rem;
      display: flex;
      font-weight: 700;
      justify-content: center;
      align-items: center;
      color: white;
    }
  }
  .userItem {
    max-width: 65%;
    text-align: left;
    margin-left: 12rem;
    font-weight: 700;
    .userName {
      font-size: 16rem;
    }
    .userRank {
      font-size: 14rem;
      margin-top: 12rem;
    }
  }
  .medal {
    width: 20%;
  }
}
