@use "../../static/scss/common.scss" as common;
.container {
  min-width: 100%;
  min-height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  display: flex;
  justify-content: center;
  z-index: 1;
  .inner {
    width: 100%;
    max-width: calc(#{common.$max-width} - 48px);
    padding: 12rem 24rem;
    background: rgba(0, 0, 0, 0.75);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #ff005b;
    font-size: 16rem;
    font-weight: 800;
    .messageArea {
      .message {
        .icon {
          margin-right: 3rem;
        }
      }
      .message:not(:first-child) {
        margin-top: 4rem;
      }
    }
    .button {
      margin-top: 8rem;
      width: 100%;
    }
  }
}
