.container {
  font-size: 12rem;
  background-color: white;
  border-radius: 12rem;
  .cardHeader {
    display: flex;
    align-items: center;
    padding: 30rem 20rem 0;
    .nameArea {
      margin-left: 16rem;
      font-weight: 700;
      .subName {
        font-size: 12rem;
      }
      .name {
        font-size: 18rem;
      }
    }
  }
  .content {
    margin-top: 16rem;
    padding: 0 20rem 20rem;
    font-size: 14rem;
    font-weight: 500;
  }
}
