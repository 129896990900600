.container {
  margin-bottom: 24rem;
}

.selectAreaContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14rem;
  font-weight: 700;
  line-height: 150%;
}

.select {
  width: 60%;
  padding: 13.5rem 16rem;
  font-size: 16rem;
  border-radius: 32rem;
}

.label {
  display: block;
  font-size: 14rem;
  font-weight: 700;
  line-height: 150%;
  margin-bottom: 12rem;
}

.caption {
  color: #747474;
  margin-top: 0rem;
  margin-bottom: 0rem;
  font-size: 10rem;
  justify-content: center;
  align-items: left;
}
