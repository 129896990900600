.imageContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14rem 16rem;
  .img {
    display: block;
  }  
}

.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .body {
    /* プライバシーポリシー全体のスタイル */
    .serviceterm {
    line-height: 1.6;
    margin: 20px;
    padding: 20px;
    font-size: 10.5em;
    }

    /* 見出しのスタイル */
    .title {
      margin-bottom: 3px;
      color: #00a08d;
    }
    /* 見出しのスタイル */
    .heading {
      margin-bottom: 3px;
      color: #333;
    }

    /* パラグラフのスタイル */
    .paragraph {
      margin-bottom: 15px;
    }
    /* リストのスタイル */
    .list {
      margin-bottom: 0px;
      padding-left: 10px;
    }
    .listItem{
      margin:0px;
      padding:0px;
    }
    /* 強調テキストのスタイル */
    .strongText {
      font-weight: bold;
    }
    /* お問い合わせセクションのスタイル */
    .contactInfo {
      margin-top: 20px;
      padding: 10px;
      border-radius: 5px;
    }
  }
}

