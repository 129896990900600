.container {
  display: flex;
  color: #000;
  font-size: 14rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  .comment {
    border-radius: 16rem;
    position: relative;
    display: inline-block;
    margin: 1.5em 0 1.5em 20rem;
    padding: 12rem;
    background: #fffbd6;
  }

  .comment:before {
    content: "";
    position: absolute;
    top: 50%;
    left: -30rem;
    margin-top: -15rem;
    border: 15rem solid transparent;
    border-right: 15rem solid #fffbd6;
  }
}
