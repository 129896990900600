.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .header {
    font-size: 16rem;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
  }
  .title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 18rem;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
  }
  .teacherComment {
    width: 100%;
  }
  .formBlock {
    width: 100%;
    .formContent {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .formHeader {
        width: 100%;
        margin-bottom: 24px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 140%;
      }
      .removeChild {
        margin-bottom: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        cursor: pointer;
        .removeText {
          margin-left: 8px;
          color: #000;
          font-size: 14rem;
          font-style: normal;
          font-weight: 500;
          line-height: 150%; /* 21px */
          text-decoration-line: underline;
          text-decoration-style: solid;
          text-decoration-skip-ink: none;
          text-decoration-thickness: auto;
          text-underline-offset: auto;
          text-underline-position: from-font;
        }
      }
      .formHeader::before {
        content: "";
        position: absolute;
        height: 1px;
        width: 40%;
        left: 0;
        background-color: #000;
      }
      .formHeader::after {
        content: "";
        position: absolute;
        height: 1px;
        width: 40%;
        right: 0;
        background-color: #000;
      }
      .form {
        width: 100%;
      }
      .addButton {
        margin: 32px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 290px;
        height: 40px;
        border-radius: 20px;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        background-color: #444;
        color: #fff;
        font-size: 16rem;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
        cursor: pointer;
      }
    }
  }
  .submitButton {
    margin-bottom: 12px;
    width: 100%;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 24px;
    background-color: #fabf00;
    font-size: 16rem;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    cursor: pointer;
  }

  .prev {
    margin-bottom: 32px;
    color: #1784e9;
    font-size: 14rem;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 21px */
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-decoration-skip-ink: none;
    text-decoration-thickness: auto;
    text-underline-offset: auto;
    text-underline-position: from-font;
    cursor: pointer;
  }
}
