.menuIcon {
  background-color: #6bd2c0;
  color: white;
  border-radius: 50%;
  width: 36rem;
  height: 36rem;
  font-size: 9rem;
  font-weight: 700;
  letter-spacing: 0.36rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
