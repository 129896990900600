.content {
  width: 100%;
  height: 50px;
  position: relative;
  .current,
  .total {
    top: calc(50% - 12px);
    position: absolute;
    display: flex;
    width: 24px;
    height: 24px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    font-size: 14rem;
    font-weight: 700;
    line-height: 140%;
    border-radius: 50%;
  }

  .current {
    z-index: 4;
    background-color: #008e7e;
    color: #fff;
  }

  .total {
    z-index: 3;
    right: 0;
    background-color: #fff;
    color: #000;
  }

  .barYet,
  .barDone {
    position: absolute;
    height: 8px;
    border-radius: 4px 0 0 4px;
    top: calc(50% - 4px);
  }
  .barYet {
    width: 100%;
    background-color: #fff;
  }
  .barDone {
    z-index: 2;
    background-color: #008e7e;
  }
}
