.container {
  width: 100%;
  border-radius: 16px;
  background-color: #fff;
  font-size: 14rem;
  .parentContainer {
    padding: 17px 17px 17px 20px;
    display: flex;
    .label {
      color: var(--PrimitiveColors-Black, #000);
      font-size: 16px;
      font-weight: 500;
      line-height: 150%; /* 24px */
      letter-spacing: 0.64px;
    }
    .value {
      color: #747474;
      font-size: 12rem;
      margin-left: auto;
    }
    .icon {
      height: 20px;
      width: 20px;
    }
  }
  .parentContainer:not(:first-child) {
    border-top: solid 1px #d8d8d8;
  }
}
